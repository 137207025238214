<template>
  <div v-if="reservation" :id="reservation.id[0].text" class="reservation">
    <b-row
      class="justify-content-center text-center"
      style="width: 100%; margin: 0; overflow: hidden"
    >
      <b-col id="reservation-section" cols="12" class="invisible">
        <span class="tepa-h2 border-red"
          >Reservation
          <br class="d-md-none d-lg-none d-md-block" />
          <span class="tepa-h3">(reservation only)</span>
        </span>
      </b-col>
      <b-col
        v-if="reservation.btn"
        cols="11"
        class="d-md-none d-lg-none d-md-block pt-5 pb-3"
      >
        <b-button
          @click="toPage(reservation.link[0].text)"
          class="btn-reservation"
          squared
          ><span class="label"> {{ reservation.label[0].text }}</span></b-button
        >
      </b-col>
      <b-col cols="10">
        <b-row class="mt-lg-5">
          <b-col
            id="reservation-left"
            class="schedule-tepa pt-lg-4 pb-lg-4 invisible"
            col
            lg="5"
            md="12"
            cols="12"
            :style="{
              backgroundImage:
                `linear-gradient(
      180deg,
      rgba(0, 0, 0, ${0}),
      rgba(0, 0, 0, ${0})
    ),url(` +
                reservation.img.url +
                ')',
            }"
          >
            <div
              v-for="(section, index) in reservation.section_left"
              :key="index"
              style="padding: 22.43px 0 26.61px"
            >
              <h3 class="tepa-h3">
                {{ section.title[0].text }}
              </h3>
              <prismic-rich-text class="tepa-p" :field="section.info" />
            </div>
          </b-col>
          <b-col
            col
            id="reservation-right"
            lg="7"
            md="12"
            cols="12"
            class="text-lg-left text-md-center pt-lg-0 pt-4 invisible"
          >
            <b-row
              class="pl-lg-5"
              style="height: 100%; justify-content: space-around"
            >
              <b-col
                cols="12"
                style="margin-bottom: 35px"
                v-for="(section, index) in reservation.section_right"
                :key="index"
              >
                <h3 class="tepa-h3">
                  {{ section.title[0].text }}
                </h3>
                <prismic-rich-text class="tepa-p" :field="section.info" />
              </b-col>
              <b-col
                v-if="reservation.btn"
                cols="12"
                class="d-none d-lg-flex d-xl-flex"
                style="align-items: flex-end"
              >
                <b-button
                  class="btn-reservation"
                  squared
                  @click="toPage(reservation.link[0].text)"
                  ><span class="label">
                    {{ reservation.label[0].text }}</span
                  ></b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      reservation: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
  },
  mounted() {
    this.getReservation();
    $(document).scroll(function () {
      const height = $(window).scrollTop() + $(window).height();
      if ($(`#reservation-section`).length > 0) {
        if (height > $(`#reservation-section`).offset().top) {
          $(`#reservation-section`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
        }
        if (height > $(`#reservation-left`).offset().top) {
          $(`#reservation-left`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
        }
        if (height > $(`#reservation-right`).offset().top) {
          $(`#reservation-right`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
        }
      }
    });
  },
  methods: {
    async getReservation() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["Reservation"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        this.reservation = res.data.results[0].data;
      }
    },
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.reservation {
  padding: 80px 0;
}
.label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
}
.btn-reservation {
  border: 2px solid #740b1b;
  color: #740b1b;
  background-color: white;
  max-width: 338px;
  width: 100%;
  height: 60px;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle .label {
  color: #fff;
  background-color: #740b1b;
  border-color: #740b1b;
}
.tepa-h3 {
  margin-bottom: 10px !important;
}
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
}
.schedule-tepa {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.tepa-h4 {
  font-family: Open Sans;
  line-height: 27px;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .reservation {
    padding: 40px 0 0;
  }
  .tepa-h2 {
    white-space: pre-line;
    line-height: 34px;
  }
  .tepa-h4 {
    font-family: Open Sans;
    line-height: 23px;
    font-weight: 400;
    font-size: 14px !important;
  }
}
</style>